import React from "react";
import OrderHistory from "./orderHistory";
import "./welcomePage.css";

const RenderWelcomePage = ({
    setShowWelcomePage,
    orderHistory,
    cart,
    pricingAgreements,
    setCart,
    updateTotalPrice,
    initialQuantities,
    setQuantities,
    isOrderHistoryLoading,
}) => {
    return (
        <div className="welcome-page-container">
            <h1>Lastechniek Holland bestelportaal</h1>
            <p>
                Welkom bij het bestelportaal van Lastechniek Holland. Hier kunt u bestellingen plaatsen en uw account beheren.
            </p>
            <button className="smallbutton" onClick={() => {
                setShowWelcomePage(false);
                window.scrollTo(0, 0);
                }}>
                Doorgaan naar de productenlijst
            </button>
            <OrderHistory
                orderHistory={orderHistory}
                cart={cart}
                pricingAgreements={pricingAgreements}
                setCart={setCart}
                updateTotalPrice={updateTotalPrice}
                initialQuantities={initialQuantities}
                setQuantities={setQuantities}
                setShowWelcomePage={setShowWelcomePage}
                isOrderHistoryLoading={isOrderHistoryLoading}
            />
        </div>
    );
}

export default RenderWelcomePage;