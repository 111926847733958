import React from "react";
import { getProductListForCustomer, getPricingAgreementsForCustomer } from "../../api/crmAPI";
import { authenticatePassword, getOrderHistory } from "../../api/salesAPI";
import "./loginPortal.css";

const LoginPortal = ({
    customerName,
    password,
    setCustomerName,
    setPassword,
    setLoggedIn,
    message,
    setMessage,
    isLoggingIn,
    setIsLoggingIn,
    setShowWelcomePage,
    setSortedProducts,
    setPricingAgreements,
    setDeliveryAddresses,
    setCustomerInvoiceMandatory,
    setSelectedAddressID,
    setTransportationCosts,
    setSafetyCosts,
    setOrderHistory,
    setIsOrderHistoryLoading,
}) => {
    const fetchPricingAgreements = (customerName) => {
        if (customerName) {
            getPricingAgreementsForCustomer(customerName)
                .then((response) => {
                    setPricingAgreements(response.data.PricingAgreements);
                    setDeliveryAddresses(response.data.DeliveryAddresses);
                    setCustomerInvoiceMandatory(response.data.CustomerInvoiceMandatory);

                    // Filter out the active delivery addresses
                    const activeDeliveryAddresses = response.data.DeliveryAddresses.filter((address) => address.IsActive === "TRUE");
                    // Automatically select the address if there is only one active address
                    if (activeDeliveryAddresses.length === 1) {
                        setSelectedAddressID(activeDeliveryAddresses[0].AddressID);
                    };
                    setTransportationCosts(response.data.TransportationCosts);
                    setSafetyCosts(response.data.SafetyCosts);
                })
                .catch((error) => {
                    console.error("Error fetching pricing agreements:", error);
                });
        }
    };
    const fetchProductListForCustomer = (customerName) => {
        if (customerName) {
            getProductListForCustomer(customerName)
                .then((response) => {
                    setSortedProducts(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching product list:", error);
                });
        }
    };

    const fetchOrderHistory = (customerName) => {
        if (customerName) {
            setIsOrderHistoryLoading(true);
            getOrderHistory(customerName)
                .then((response) => {
                    setOrderHistory(response.data);
                    setIsOrderHistoryLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching order history:", error);
                    setIsOrderHistoryLoading(false);
                })

        }
    };
    const handleLogin = async () => {
        if (!customerName || !password) {
            setMessage("Gebruikersnaam en wachtwoord moeten ingevuld zijn");
            return;
        }

        setIsLoggingIn(true);

        try {
            const response = await authenticatePassword({
                customerName,
                password,
            });

            if (response.data.message === "Authentication successful") {
                setLoggedIn(true);
                setMessage("Inlogggen gelukt");
                fetchPricingAgreements(customerName);
                fetchOrderHistory(customerName);
                fetchProductListForCustomer(customerName);
            } else if (
                response.data.error ===
                "Too many failed login attempts for this account, please try again later"
            ) {
                setMessage("Te veel mislukte pogingen. Probeer het later opnieuw.");
            } else {
                setLoggedIn(false);
                setMessage("Inloggen mislukt");
            }
        } catch (error) {
            if (
                error.response?.data?.error ===
                "Too many failed login attempts for this account, please try again later"
            ) {
                setMessage("Te veel mislukte pogingen. Probeer het later opnieuw.");
            } else {
                console.error("Login error: ", error);
                setMessage("Inloggen mislukt");
            }
        } finally {
            setIsLoggingIn(false);
            setShowWelcomePage(true);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleLogin();
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h3>Inloggen</h3>
                <div>
                    <label>
                        Gebruikersnaam:
                        <input
                            type="text"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Wachtwoord:
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </label>
                </div>
                <div>
                    <button type="submit" disabled={isLoggingIn}>
                        {isLoggingIn ? (
                            <div className="button-content">
                                <div className="spinner"></div> Inloggen...
                            </div>
                        ) : (
                            "Inloggen"
                        )}
                    </button>
                </div>
                <p>{message}</p>
            </form>
        </div>
    );
};

export default LoginPortal;