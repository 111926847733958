import{ apiServiceAWS } from './apiService';


export const submitOrder = (data) => {
    return apiServiceAWS.post('/salesportal/submitorder', data);
};

export const getOrderHistory = (customerName) => {
    return apiServiceAWS.get(`/salesportal/getorderhistorycustomer/${customerName}`);
};

export const getLastInvoice = () => {
    return apiServiceAWS.get('/salesportal/getlastinvoice');
};

export const authenticatePassword = (data) => {
    return apiServiceAWS.post('/salesportal/authenticate', data);
};

export const changePassword = (data) => {
    return apiServiceAWS.post('/salesportal/changepassword', data);
};