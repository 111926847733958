import React, { useState } from "react";
import "./orderHistory.css";

const OrderHistory = ({
    orderHistory,
    cart,
    pricingAgreements,
    setCart,
    updateTotalPrice,
    initialQuantities,
    setQuantities,
    setShowWelcomePage,
    isOrderHistoryLoading,
}) => {
    const [orderHistoryCurrentPage, setOrderHistoryCurrentPage] = useState(1);
    const orderHistoryEntriesPerPage = 10;
    const getOrderHistoryForCurrentPage = () => {
        const startIndex = (orderHistoryCurrentPage - 1) * orderHistoryEntriesPerPage;
        const endIndex = startIndex + orderHistoryEntriesPerPage
        return orderHistory.slice(startIndex, endIndex);
    };

    const handleOrderHistoryPageChange = (event) => {
        if (event.target.innerText === 'Vorige' && orderHistoryCurrentPage > 1) {
            setOrderHistoryCurrentPage(orderHistoryCurrentPage -1);
        } else if (
            event.target.innerText === 'Volgende' &&
            orderHistoryCurrentPage <
            Math.ceil(orderHistory.length / orderHistoryEntriesPerPage)
        ) {
            setOrderHistoryCurrentPage(orderHistoryCurrentPage + 1);
        }
    };

    const handleRepeatOrder = async (order) => {
        try {
            const updatedCart = [...cart];

            for (const detail of order.OrderDetails) {
                const productId = detail.ProductID;
                const quantity = detail.SaleQuantity;
                const product = pricingAgreements.find((agreement) => agreement.ProductID === productId);

                const existingProductIndex = updatedCart.findIndex((item) => item.productId === productId);

                if (existingProductIndex !== -1) {
                    // If the product is already in the cart, update its quantity
                    updatedCart[existingProductIndex].quantity += quantity;
                } else {
                    // If the product is not in the cart, add it
                    updatedCart.push({
                        productId,
                        quantity,
                        unitPrice: product.Price,
                    });
                }
            }

            // Update the cart and total price in the state
            setCart(updatedCart);
            updateTotalPrice(updatedCart);

            // Reset the quantities to 0
            setQuantities(initialQuantities);

            setShowWelcomePage(false);
            window.scrollTo(0, 0);
        } catch (error) {
            console.error("Error handling repeated order:", error);
        } 
    };

    const currentOrderHistory = getOrderHistoryForCurrentPage();
    const totalPages = Math.ceil(orderHistory.length / orderHistoryEntriesPerPage);

    if (isOrderHistoryLoading) {
        return <div className="table-container-orderhistory">
            <p>Bestelhistorie:</p>
            <div className="spinner"></div> Bestelhistorie ophalen...
        </div>;
    }

    if (orderHistory.length === 0) {
        return <div className="table-container-orderhistory">
            <p>Bestelhistorie:</p>
            <p>Geen bestellingen gevonden.</p>
        </div>;
    }

    return (
        <div className="table-container-orderhistory">
            <h4>Bestelhistorie:</h4>
            <table className="orderhistory-table">
                <thead>
                    <tr>
                        <th>Besteldatum</th>
                        <th>Ordernummer</th>
                        <th>Product & Hoeveelheid</th>
                        <th>Actie</th>
                    </tr>
                </thead>
                <tbody>
                    {currentOrderHistory?.map((order) => (
                        <tr key={order.OrderID}>
                            <td className="bordered-cell">
                                {order.OrderDate ? new Date(order.OrderDate).toLocaleDateString('nl-NL') : ''}
                            </td>
                            <td className="bordered-cell">{order.InvoiceNumber}</td>
                            <td className="bordered-cell">
                                {order.OrderDetails?.map((detail, index) => (
                                    <div key={index} className="product-quantity">
                                        <span>{detail.ProductName}</span>
                                        <span className="quantity-text">{detail.SaleQuantity} cilinder(s)</span>
                                    </div>
                                ))}
                            </td>
                            <td className="bordered-cell">
                                <button className="smallbutton" onClick={() => handleRepeatOrder(order)}>Herhaal bestelling</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button className="smallbutton"
                    onClick={handleOrderHistoryPageChange}
                    disabled={orderHistoryCurrentPage <= 1}
                >
                    Vorige
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                    <button 
                        key={i + 1}
                        onClick={() => setOrderHistoryCurrentPage(i + 1)}
                        disabled={orderHistoryCurrentPage === i + 1}
                    >
                        {i + 1}
                    </button>
                ))}
                <button className="smallbutton"
                    onClick={handleOrderHistoryPageChange}
                    disabled={orderHistoryCurrentPage >= totalPages}
                >
                    Volgende
                </button>
            </div>
        </div>
    )
}

export default OrderHistory;
