import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { 
    submitOrder,
    getLastInvoice,
} from "../api/salesAPI";
import LoginPortal from "./LoginPortal/LoginPortal";
import RenderWelcomePage from "./WelcomePage/welcomePage";
import "./salesPortal.css";
import { renderProductList } from "./ProductPage/productList";
import { renderShoppingCart } from "./ProductPage/shoppingCart";
import RenderOrderConfirmation from "./OrderConfirmation/OrderConfirmation";
import LHlogo from "./LH.png";

const SalesPortal = () => {
    const navigate = useNavigate();
    const [customerName, setCustomerName] = useState("");
    const [password, setPassword] = useState("");
    const [pricingAgreements, setPricingAgreements] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [message, setMessage] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    const [cart, setCart] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedDeliveryDate, setSelectedDeliveryDate] = useState("");
    const [orderConfirmation, setOrderConfirmation] = useState(false);
    const [deliveryAddresses, setDeliveryAddresses] = useState([]);
    const [orderComments, setOrderComments] = useState("");
    const [customerInvoice, setCustomerInvoice] = useState("");
    const [confirmationMessage, setConfirmationMessage] = useState(false);
    const [confirmationMessageText, setConfirmationMessageText] = useState("");
    const [orderHistory, setOrderHistory] = useState([]);
    const [transportationCosts, setTransportationCosts] = useState(0);
    const [safetyCosts, setSafetyCosts] = useState(0);
    const SESSION_TIMEOUT_DURATION = 1000 * 60 * 60; // 60 minutes
    const [isSubmitting, setisSubmitting] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isOrderHistoryLoading, setIsOrderHistoryLoading] = useState(false);
    const [showWelcomePage, setShowWelcomePage] = useState(false);
    const [selectedAddressID, setSelectedAddressID] = useState("");
    const [sortedProducts, setSortedProducts] = useState([]);
    const [customerInvoiceMandatory, setCustomerInvoiceMandatory] = useState(false);

    const initialQuantities = {};
    pricingAgreements.forEach((agreement) => {
        initialQuantities[agreement.ProductID] = 0;
    });

    const handleQuantityChange = (productId, quantity) => {
        // Update quantities in the state when the user selects a quantity
        setQuantities({ ...quantities, [productId]: quantity });
    };

    // Calculate the total quantity in the cart
    const totalQuantityInCart = cart.reduce((total, item) => total + item.quantity, 0);

    const handleUpdateQuantity = async (productId, newQuantity) => {
        if (newQuantity < 0) {
            return;
        }
        try {
            const updatedCart = [...cart];
            const productIndex = updatedCart.findIndex((item) => item.productId === productId);

            if (productIndex !== -1) {
                updatedCart[productIndex].quantity = newQuantity;
                setCart(updatedCart);
                updateTotalPrice(updatedCart);
            }
        } catch (error) {
            console.error("Error updating quantity:", error);
        }
    };

    const handleRemoveFromCart = (productId) => {
        const updatedCart = cart.filter((item) => item.productId !== productId);
        setCart(updatedCart);
        updateTotalPrice(updatedCart);
    };

    const updateTotalPrice = (cartItems) => {
        const updatedTotalPrice = cartItems.reduce(
            (total, item) => total + item.quantity * item.unitPrice,
            0
        );
        setTotalPrice(updatedTotalPrice);
    }

    const handleAddtoCart = async (productId) => {
        const quantity = quantities[productId] || 0;
        const product = pricingAgreements.find((agreement) => agreement.ProductID === productId);
        try {
            
            if (quantity > 0) {
                // Create a copy of the current cart and update it
                const updatedCart = [...cart];
                const existingProductIndex = updatedCart.findIndex((item) => item.productId === productId);

                if (existingProductIndex !== -1) {
                    // If the product is already in the cart, update its quantity
                    updatedCart[existingProductIndex].quantity += quantity;
                } else {
                    // If the product is not in the cart, add it
                    updatedCart.push({
                        productId,
                        quantity,
                        unitPrice: product.Price,
                    });
                }

                // Calculate the total price
                const updatedTotalPrice = updatedCart.reduce((total, item) => total + item.quantity * item.unitPrice, 0);

                // Update the cart and total price in the state
                setCart(updatedCart);
                setTotalPrice(updatedTotalPrice);

                // Reset the quantity to 0
                setQuantities({ ...quantities, [productId]: 0 });
            }
        } catch (error) {
            console.error("Error adding to cart:", error);
        }
    };


    const handleSubmitOrder = async () => {
        if (!selectedAddressID) {
            alert("U heeft nog geen bezorgadres opgegeven.");
            return;
        }

        setisSubmitting(true);

        // Create an array to store the products with their quantities
        const productsWithQuantities = [];

        // Loop through the cart and populate the productsWIthQuantities array
        cart.forEach((item) => {
            const productId = item.productId
            const quantity = item.quantity;
            const unitPrice = item.unitPrice;

            if (quantity > 0) {
                productsWithQuantities.push({
                    ProductID: productId,
                    SaleQuantity: quantity,
                    UnitPrice: unitPrice,
                });
            }
        });
        
        //Collect deliveryAddress data
        // const selectedAddress = deliveryAddresses.find((address) => address.selected);
        // console.log(selectedAddress);

        // if (!selectedDeliveryAddressID) {
        //     setMessage("U heeft nog geen bezorgadres opgegeven.");
        //     return;
        // }

        // Get the selected delivery date
        const formattedDeliveryDate = selectedDeliveryDate.toLocaleDateString('nl-NL');
        const formattedDeliveryDateParts = formattedDeliveryDate.split('-');
        // Get the selected delivery date in the right format for the SQL injection
        const formattedDeliveryDateSQL = `${formattedDeliveryDateParts[2]}-${formattedDeliveryDateParts[1]}-${formattedDeliveryDateParts[0]}`

        // count from the last invoicenumber in the database
        let lastInvoiceNumber = "";
        try {
            const response = await getLastInvoice();
            lastInvoiceNumber = response.data.lastInvoice;
        } catch (error) {
            console.error("Error fetching last invoice number:", error);
        }

        // Calculate the next invoice number
        const numericPart = parseInt(lastInvoiceNumber.slice(4));
        const defaultInvoiceNumber = `LHP-${(numericPart + 1).toString().padStart(6, '0')}`;

        const order = {
            CustomerName: customerName,
            InvoiceNumber: defaultInvoiceNumber,
            CustomerInvoice : customerInvoice,
            TransportationCosts: transportationCosts,
            SafetyCosts: safetyCosts,
            Comment: orderComments,
            SaleType: 'PortalSale',
            products: productsWithQuantities,
            SelectedDeliveryAddress: selectedAddressID,
            PreferredDeliveryDate: formattedDeliveryDate,
            PreferredDeliveryDateSQL: formattedDeliveryDateSQL,
        };

        submitOrder(order)
            .then((response) => {
                // Handle order submission success
                if (response.data && response.data.message) {
                    setConfirmationMessageText("Bedankt voor uw bestelling. U ontvangt een bevestiging per email. Kunt u geen email vinden? Controleer dan uw spambox.");

                } else {
                    // This is probably obsolete because if the API does not return a message, the catch block will be executed
                    setConfirmationMessageText("Er is een fout opgetreden bij het plaatsen van uw bestelling. Probeer het later nog eens.");
                }
                setConfirmationMessage(true);
                setOrderConfirmation(false);
                setisSubmitting(false);
            })
            .catch((error) => {
                console.error("Error submitting order:", error);
                setConfirmationMessageText("Er is een fout opgetreden bij het plaatsen van uw bestelling. Probeer het later nog eens.");
                setConfirmationMessage(true);
                setOrderConfirmation(false);
                setisSubmitting(false);
            });


    };

    const handleDeliveryDateChange = (date) => {
        setSelectedDeliveryDate(date);
    };

    const handleBackButtonClick = () => {
        // Set orderconfirmation to false to navigate back to the product list
        setOrderConfirmation(false);
        window.scrollTo(0, 0);

        if (deliveryAddresses.length > 1) {
            setSelectedAddressID("");
        }
    };

    const handleLogout = () => {
        setLoggedIn(false);
        setCustomerName("");
        setPassword("");
        setMessage("");
        setOrderConfirmation(false);
        setConfirmationMessage(false);
        // setPricingAgreements([]);
        setCart([]);
        // setTotalPrice(0);
        setSelectedDeliveryDate("");
        // setAvailableDeliveryDates([]);
        // setDeliveryAddresses([]);
        setOrderComments("");
        setCustomerInvoice("");
        setOrderHistory([]);
        // setOrderHistoryCurrentPage(1);
        setSelectedAddressID("");
        if (process.env.NODE_ENV === 'development') {
            window.location.href = 'http://localhost:3000/';
        } else {
            window.location.href = 'https://www.bestel-lastechniekholland.nl/';
        }
    };

    const handleShowPasswordChange = () => {
        navigate(`/wachtwoord-wijzigen/${customerName}`);
    };

    useEffect(() => {
        if (!loggedIn) {
            return;
        }
        let timeout;

        const handleUserActivity = () => {
            clearTimeout(timeout); // Clear the timeout on user activity
            timeout = setTimeout(() => {
                handleLogout();
            }, SESSION_TIMEOUT_DURATION);
        };

        window.addEventListener("mousemove", handleUserActivity);
        window.addEventListener("mousedown", handleUserActivity);
        window.addEventListener("keypress", handleUserActivity);
        window.addEventListener("scroll", handleUserActivity);

        timeout = setTimeout(() => {
            handleLogout();
        }, SESSION_TIMEOUT_DURATION);

        return () => {
            window.removeEventListener("mousemove", handleUserActivity);
            window.removeEventListener("mousedown", handleUserActivity);
            window.removeEventListener("keypress", handleUserActivity);
            window.removeEventListener("scroll", handleUserActivity);
            clearTimeout(timeout);
        };
    }, [loggedIn, SESSION_TIMEOUT_DURATION]);

    return (
        <div>
            <div className="header">
                <span className="header-icon">▶</span>
                <h1 className="header-title">Bestelportaal</h1>
                <img src={LHlogo} alt="LH logo" className="LH-logo"/>
            </div>
    
            {/* Welcome message and logout button container */}
            {loggedIn && (
                <div className="welcome-container">
                    <div className="welcome-message">
                        <p>Welkom {customerName}</p>
                    </div>
                    <div className="button-group">
                        {!confirmationMessage && <span className="cart-quantity">Aantal cilinders in Winkelwagen: {totalQuantityInCart}</span>}
                        <button className="change-password-button" onClick={handleShowPasswordChange}>Wachtwoord wijzigen</button>
                        <button className="logout-button" onClick={handleLogout}>Uitloggen</button>
                    </div>
                </div>
            )}
    
            {/* Render login form if not logged in */}
            {!loggedIn && (
                <LoginPortal
                    customerName={customerName}
                    password={password}
                    setCustomerName={setCustomerName}
                    setPassword={setPassword}
                    setLoggedIn={setLoggedIn}
                    message={message}
                    setMessage={setMessage}
                    isLoggingIn={isLoggingIn}
                    setIsLoggingIn={setIsLoggingIn}
                    setShowWelcomePage={setShowWelcomePage}
                    setSortedProducts={setSortedProducts}
                    setPricingAgreements={setPricingAgreements}
                    setDeliveryAddresses={setDeliveryAddresses}
                    setCustomerInvoiceMandatory={setCustomerInvoiceMandatory}
                    setSelectedAddressID={setSelectedAddressID}
                    setTransportationCosts={setTransportationCosts}
                    setSafetyCosts={setSafetyCosts}
                    setOrderHistory={setOrderHistory}
                    setIsOrderHistoryLoading={setIsOrderHistoryLoading}
                />
            )}
            {/* Render welcome page if showWelcomePage is true */}
            {loggedIn && showWelcomePage && 
            <RenderWelcomePage 
                setShowWelcomePage={setShowWelcomePage}
                orderHistory={orderHistory}
                cart={cart}
                pricingAgreements={pricingAgreements}
                setCart={setCart}
                updateTotalPrice={updateTotalPrice}
                initialQuantities={initialQuantities}
                setQuantities={setQuantities}
                isOrderHistoryLoading={isOrderHistoryLoading}
            />
            }
            {/* Render order confirmation screen if logged in and order confirmation is true */}
            {loggedIn && !showWelcomePage && orderConfirmation && <RenderOrderConfirmation
                cart={cart}
                pricingAgreements={pricingAgreements}
                transportationCosts={transportationCosts}
                safetyCosts={safetyCosts}
                deliveryAddresses={deliveryAddresses}
                customerInvoice={customerInvoice}
                orderComments={orderComments}
                handleSubmitOrder={handleSubmitOrder}
                selectedDeliveryDate={selectedDeliveryDate}
                totalPrice={totalPrice}
                setCustomerInvoice={setCustomerInvoice}
                setOrderComments={setOrderComments}
                handleBackButtonClick={handleBackButtonClick}
                isSubmitting={isSubmitting}
                setSelectedAddressID={setSelectedAddressID}
                customerInvoiceMandatory={customerInvoiceMandatory}
            />}
    
            {/* Render a confirmation message */}
            {confirmationMessage && (
                <div>
                    <p className="confirmation-message">{confirmationMessageText}</p>
                </div>
            )}
    
            {/* Render product list, cart and order button if not submitted */}
            {loggedIn && !orderConfirmation && !confirmationMessage &&
             !showWelcomePage && (
                <div>
                    {pricingAgreements && pricingAgreements.length > 0 && (
                        <>
                            {renderProductList(
                                customerName,
                                sortedProducts,
                                quantities,
                                handleQuantityChange,
                                handleAddtoCart
                            )}
                            {cart.length > 0 && renderShoppingCart(
                                pricingAgreements,
                                cart,
                                handleUpdateQuantity,
                                handleRemoveFromCart,
                                totalPrice,
                                selectedDeliveryDate,
                                handleDeliveryDateChange,
                                setOrderConfirmation,
                            )}
                        </>
                    )}
                    <button className="smallbutton" onClick={() => {
                        setShowWelcomePage(true);
                        window.scrollTo(0, 0);
                    }}
                    style={{marginLeft: '10px'}}
                    >
                        Terug naar de welkomstpagina
                    </button>
                </div>
            )}
        </div>
    );
};

export default SalesPortal;
